#instantSearch {
  font-weight: 500;

  .instantSearchShortcut {
    border-radius: 0.25rem;
    padding: 0.35rem 0.35rem;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    .instantSearchShortcutText {
      line-height: 1;
      font-weight: 600;
    }
  }
}
