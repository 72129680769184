#sidebarList {
  &.parentElement {
    margin: 0 0.5rem;
  }

  &:not(&.parentElement) {
    margin-left: 1.5rem;
    padding-block: 0;
  }
}
