@use '../../../styles/breakpoints';

#dashboard {
  .app {
    display: grid;
    @include breakpoints.create(xl) {
      grid-template-columns: 275px 1fr;
    }

    .posContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
  }
  .container {
    max-width: unset;
    margin-block: 1.25rem;
    position: relative;

    .listOfContentsContainer {
      position: absolute;
      height: max-content;
      width: 300px;
      top: 50px;
      right: 0;
    }
  }
}
