.posIntegration {
	width: 80%;

	.heading {
		margin-bottom: 0.5rem !important;
	}
	
	.img {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	
	.section {
		margin-top: 2rem;
	}

	ol > li::marker {
		font-weight: 500;
		font-size: 1rem;
		line-height: 1.375;
	}
}
