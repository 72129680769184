@use '../../../../styles/colors';

#sidebarContainer {
  height: calc(100vh - 66px);
  overflow-y: scroll;
  scrollbar-width: auto;
  border-right: 1px solid colors.$grey200;
  min-width: 275px;
  position: sticky;
  top: 65px; // Height of navbar

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: colors.$grey200;
    }
  }

  #sidebar {
    background: #F9F9F9;
    margin: 20px;
    border-radius: 16px;
  }
}

.sidebarHeader {
  border-bottom: 1px solid colors.$grey100;
  padding-left: 1.5rem;
}

.mobileSidebar {
  border-radius: 0 0.5rem 0.5rem 0;
}
