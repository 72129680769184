@use "../../../../styles/colors";

#instantSearchListItem {
  border: 1px solid transparent;
  border-radius: 0.5rem;
  padding-block: 0.55rem;

  &.listItemChild {
    &:hover,
    &:focus {
      background-color: lighten(colors.$primaryMain, 31);
      border-color: colors.$primaryMain;
      color: colors.$primaryMain;
    }
  }

  .highLightText {
    color: colors.$primaryMain;
  }

  &:hover,
  &:focus {
    background-color: lighten(colors.$primaryMain, 31);
    border-color: colors.$primaryMain;
    color: colors.$primaryMain;
    .highLightText {
      text-decoration: underline;
    }
  }
}

#listCaption {
  .listCaptionText {
    font-weight: 500;
    .highLightText {
      text-decoration: underline;
      text-decoration-color: colors.$primaryMain;
      color: colors.$primaryMain;
    }
  }
}
