@use '../../../../styles/colors';

.sidebarListItem {
  outline: none;
  border: none;
  background: none;
  width: 100%;
  height: 2.5rem;
  text-align: left;
  padding: 0 10px;
  font-family: 'Inter', sans-serif;
  color: colors.$black;
  margin: 10px 0;
  font-size: 16px;
}

.sidebarListItemActive {
  @extend .sidebarListItem;
  border-left: 2px solid colors.$primaryMain;
  background-color: #FFF0F3;
  color: colors.$primaryMain;
  font-weight: 600;
}
