@use "../../../../styles/colors";

#navbar {
  border-bottom: 1px solid colors.$grey200;

  .navbarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navLeft,
    .navRight {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
    .navRight {
      gap: 1rem;
    }
  }
}
