$primaryMain: #f2755f;
$primaryLight: #f48977;
$primaryDark: #f06147;
$primaryContrastText: #ffffff;

$secondaryMain: #6c757d;
$secondaryLight: #78828a;
$secondaryDark: #242A31;
$secondaryContrastText: #ffffff;

$successMain: #198754;
$successLight: #1d9d61;
$successDark: #157147;
$successContrastText: #ffffff;

$infoMain: #2196f3;
$infoLight: #64b6f7;
$infoDark: #0b79d0;
$infoContrastText: #ffffff;

$warningMain: #ffb020;
$warningLight: #ffbf4c;
$warningDark: #b27b16;
$warningContrastText: #ffffff;

$errorMain: #d14343;
$errorLight: #da6868;
$errorDark: #922e2e;
$errorContrastText: #ffffff;

$textPrimary: #333333;
$textSecondary: #65748b;
$textDisabled: #3741517a;

$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #bdbdbd;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey800: #424242;
$grey900: #212121;
$greyA100: #f5f5f5;
$greyA200: #eeeeee;
$greyA400: #bdbdbd;
$greyA700: #616161;
$white: #ffffff;
$black: #000000;


:export {
  primaryMain: $primaryMain;
  primaryLight: $primaryLight;
  primaryDark: $primaryDark;
  primaryContrastText: $primaryContrastText;
  secondaryMain: $secondaryMain;
  secondaryLight: $secondaryLight;
  secondaryDark: $secondaryDark;
  secondaryContrastText: $secondaryContrastText;
  successMain: $successMain;
  successLight: $successLight;
  successDark: $successDark;
  successContrastText: $successContrastText;
  infoMain: $infoMain;
  infoLight: $infoLight;
  infoDark: $infoDark;
  infoContrastText: $infoContrastText;
  warningMain: $warningMain;
  warningLight: $warningLight;
  warningDark: $warningDark;
  warningContrastText: $warningContrastText;
  errorMain: $errorMain;
  errorLight: $errorLight;
  errorDark: $errorDark;
  errorContrastText: $errorContrastText;
  textPrimary: $textPrimary;
  textSecondary: $textSecondary;
  textDisabled: $textDisabled;
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey800: $grey800;
  grey900: $grey900;
  greyA100: $greyA100;
  greyA200: $greyA200;
  greyA400: $greyA400;
  greyA700: $greyA700;
}
